import { User as FirebaseUser } from '@firebase/auth';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import i18n from '../i18n';
import Notification from '../components/Notification';
import FileUploadModal from '../components/FileUploadModal';
import DocumentShareModal from '../components/DocumentShareModal';

interface getDocumentAccessControlResponse {
  id: string;
  maxAccessCount: number;
  documentName: string;
  documentId: string;
  note: string;
  preSharedKeyword: string;
  availableFrom: number;
  expireAt: number;
  createdAt: number;
  updatedAt: number;
}

export default function DocumentAccessControlList({
  currentUser,
}: {
  currentUser: FirebaseUser;
}) {
  const [documentAccessControls, setDocumentAccessControls] = useState<
    getDocumentAccessControlResponse[] | []
  >([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');

  const fetchDocumentAccessControls = () => {
    const userId = currentUser.uid;
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `http://localhost:8080/document-access-controls/?userId=${userId}`,
          {
            headers: { 'X-NE_USER_TOKEN': idToken },
          }
        )
          .then(async (response) => await response.json())
          .then((data) => {
            setDocumentAccessControls(data.document_access_controls);
          });
      })
      .catch(function (error) {
        // Handle error
      });
  };

  useEffect(() => {
    fetchDocumentAccessControls();
  }, []);
  const parseDate = (unix: number): string => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.unix(unix).tz(timeZone).format('YYYY-MM-DD hh:mm').toString();
  };

  const copyLink = (str: string) => {
    const url = `http://localhost:3000/receive/${str}`;
    navigator.clipboard.writeText(url).then(() => {
      setOpenNotification(true);
      const message = i18n.t('notification.success_copy_url');
      setInfoMessage(message);
    });
  };
  const deleteDocument = (documentId: string) => {
    const confirmMessage = i18n.t(
      'confirmation.are_you_sure_to_delete_document'
    );
    if (!confirm(confirmMessage)) {
      return;
    }
    const userId = currentUser.uid;
    const formData = new FormData();
    formData.append('documentId', documentId);
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(`http://localhost:8080/documents/?userId=${userId}`, {
          headers: { 'X-NE_USER_TOKEN': idToken },
          method: 'DELETE',
          body: formData,
        }).then((response) => {
          if (response.status === 200) {
            const message = i18n.t('notification.success_delete_document');
            setOpenNotification(true);
            setInfoMessage(message);
          }
        });
      })
      .catch(function (error) {
        // Handle error
      });
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      <Notification
        infoMessage={infoMessage}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {i18n.t('common.document_share_tracking')}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {i18n.t('explanations.documents.document_list')}
          </p>
        </div>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none text-right pt-3 text-gray-400 font-light">
        Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
      </div>

      <div className="mt-1 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {i18n.t('common.document_name')}
                    </th>
                    <th scope="col" className="relative">
                      <span className="sr-only">Share link</span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('common.note')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('common.access_count')}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('common.expiration')}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('common.updated_at')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('common.created_at')}
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {documentAccessControls &&
                    documentAccessControls.map(
                      (record: getDocumentAccessControlResponse) => (
                        <tr key={record.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {record.documentName}
                          </td>
                          <td className="relative whitespace-nowrap py-1 pl-1 pr-1 text-right text-sm font-medium">
                            <button
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => {
                                copyLink(record.id);
                              }}
                            >
                              {i18n.t('actions.copy_link')}
                              <span className="sr-only">, {record.id}</span>
                            </button>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {record.note}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            0 / {record.maxAccessCount}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {parseDate(record.availableFrom)} 〜{' '}
                            {parseDate(record.expireAt)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {parseDate(record.createdAt)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {parseDate(record.updatedAt)}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => {
                                deleteDocument(record.id);
                              }}
                            >
                              {i18n.t('actions.delete')}
                              <span className="sr-only">, {record.id}</span>
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
