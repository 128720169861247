import {
  ListBulletIcon,
  MagnifyingGlassIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { User as FirebaseUser } from '@firebase/auth';
import { firebaseAuth } from '../firebaseAuth';
import { redirect } from 'react-router-dom';
import i18n from '../i18n';

const isCurrent = (href: string): boolean => {
  const currentPath = location.pathname;
  return currentPath.startsWith(href);
};

const navigation = [
  {
    name: 'Tracking',
    icon: MagnifyingGlassIcon,
    href: '/tracking',
    current: isCurrent('/tracking'),
  },
  {
    name: 'File List',
    icon: ListBulletIcon,
    href: '/file-list',
    current: isCurrent('/file-list'),
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar({
  currentUser,
}: {
  currentUser: FirebaseUser | null;
}) {
  const signOut = () => {
    const confirmMessage = i18n.t('confirmation.are_you_sure_to_sign_out');
    if (!confirm(confirmMessage)) {
      return;
    }
    firebaseAuth.signOut().then((r) => {
      redirect('/login');
    });
  };
  return (
    <div className="basis-1/6 h-screen">
      <div className="flex flex-1 flex-col bg-gray-800 h-full">
        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center px-4">
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
              alt="Your Company"
            />
          </div>
          <nav
            className="mt-5 flex-1 space-y-1 bg-gray-800 px-2"
            aria-label="Sidebar"
          >
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={classNames(
                  item.current
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="flex-1">{item.name}</span>
              </a>
            ))}

            <div className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
              <ArrowRightOnRectangleIcon
                className={classNames(
                  'text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
              />
              <button onClick={signOut}>Sign out</button>
            </div>
          </nav>
        </div>
        <div className="flex flex-shrink-0 bg-gray-700 p-4">
          <a href="#" className="group block w-full flex-shrink-0">
            <div className="flex items-center">
              <div>
                <img
                  className="inline-block h-9 w-9 rounded-full"
                  src={
                    currentUser?.photoURL ||
                    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                  }
                  alt=""
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-white">
                  {currentUser?.displayName || 'Name not defined'}
                </p>
                <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                  View profile
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
