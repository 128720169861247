import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import FileUploadModal from '../components/FileUploadModal';
import { firebaseAuth } from '../firebaseAuth';
import { User as FirebaseUser } from '@firebase/auth';
import DocumentShareModal from '../components/DocumentShareModal';
import i18n from '../i18n';
import Notification from '../components/Notification';
import PreviewModal from '../components/PreviewModal';

interface accessControlOfGetFileListResponse {
  accessLimitCount: number;
  documentId: string;
  expireAt: string;
  id: string;
  preSharedKeyword: string;
  createdAt: string;
  updatedAt: string;
}

interface getFileListResponse {
  id: string;
  userId: string;
  originalFileName: string;
  fileId: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  documentAccessControls: accessControlOfGetFileListResponse;
}

export default function FileList({
  currentUser,
}: {
  currentUser: FirebaseUser;
}) {
  const [files, setFiles] = useState<getFileListResponse[] | []>([]);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openDocumentShareModal, setOpenDocumentShareModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  const fetchFileList = () => {
    const userId = currentUser.uid;
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(`http://localhost:8080/documents/?userId=${userId}`, {
          headers: { 'X-NE_USER_TOKEN': idToken },
        })
          .then(async (response) => await response.json())
          .then((data) => {
            setFiles(data.documents);
          });
      })
      .catch(function (error) {
        // Handle error
      });
  };

  useEffect(() => {
    fetchFileList();
  }, [openFileUploadModal]);
  const parseDate = (str: string): string => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(str).tz(timeZone).format('YYYY-MM-DD hh:mm Z').toString();
  };

  const deleteDocument = (documentId: string) => {
    const confirmMessage = i18n.t(
      'confirmation.are_you_sure_to_delete_document'
    );
    if (!confirm(confirmMessage)) {
      return;
    }
    const userId = currentUser.uid;
    const formData = new FormData();
    formData.append('documentId', documentId);
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(`http://localhost:8080/documents/?userId=${userId}`, {
          headers: { 'X-NE_USER_TOKEN': idToken },
          method: 'DELETE',
          body: formData,
        }).then((response) => {
          if (response.status === 200) {
            const message = i18n.t('notification.success_delete_document');
            setOpenNotification(true);
            setInfoMessage(message);
          }
        });
      })
      .catch(function (error) {
        // Handle error
      });
  };

  const previewDocument = (id: string) => {
    const userId = currentUser.uid;
    const formData = new FormData();
    formData.append('documentId', id);
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(`http://localhost:8080/documents/download?userId=${userId}`, {
          headers: { 'X-NE_USER_TOKEN': idToken },
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            const reader = response.body!.getReader();
            return new ReadableStream({
              start(controller) {
                return pump();
                function pump(): any {
                  return reader.read().then(({ done, value }) => {
                    // データを消費する必要がなくなったら、ストリームを閉じる
                    if (done) {
                      controller.close();
                      return;
                    }
                    // 次のデータチャンクを対象のストリームのキューに入れる
                    controller.enqueue(value);
                    return pump();
                  });
                }
              },
            });
          })
          .then((stream) => new Response(stream))
          // レスポンスのオブジェクト URL を作成
          .then((response) => response.blob())
          .then((blob) => URL.createObjectURL(blob))
          .then((url) => {
            console.log(url);
            setPreviewUrl(url);
            setOpenPreviewModal(true);
          });
      })

      .catch(function (error) {
        // Handle error
      });
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      <Notification
        infoMessage={infoMessage}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
      <FileUploadModal
        openFileUploadModal={openFileUploadModal}
        setOpenFileUploadModal={setOpenFileUploadModal}
      />
      <DocumentShareModal
        openFileUploadModal={openDocumentShareModal}
        setOpenFileUploadModal={setOpenDocumentShareModal}
        documentId={documentId}
        documentName={documentName}
      />
      <PreviewModal
        openPreviewModal={openPreviewModal}
        setOpenPreviewModal={setOpenPreviewModal}
        url={previewUrl}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {i18n.t('common.document_list')}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {i18n.t('explanations.documents.document_list')}
          </p>
        </div>

        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => {
              setOpenFileUploadModal(true);
            }}
          >
            {i18n.t('actions.add_document')}
          </button>
        </div>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none text-right pt-3 text-gray-400">
        Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
      </div>
      <div className="mt-1 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {i18n.t('common.document_name')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('common.note')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('common.created_at')}
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {files &&
                    files.map((file: getFileListResponse) => (
                      <tr key={file.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {file.originalFileName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {file.note}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {parseDate(file.createdAt)}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => {
                              previewDocument(file.id);
                            }}
                          >
                            だうんろーど
                            <span className="sr-only">, {file.id}</span>
                          </button>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => {
                              setDocumentId(file.id);
                              setDocumentName(file.originalFileName);
                              setOpenDocumentShareModal(true);
                            }}
                          >
                            {i18n.t('actions.share_document')}
                            <span className="sr-only">, {file.id}</span>
                          </button>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => {
                              deleteDocument(file.id);
                            }}
                          >
                            {i18n.t('actions.delete')}
                            <span className="sr-only">, {file.id}</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
