import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      actions: {
        add_document: 'Add document',
        share_document: 'Create share document URL',
        delete: 'Delete',
        copy_link: 'Copy link',
      },
      common: {
        document_share_tracking: 'Track your shared document',
        document_list: 'Document list',
        document_id: 'Document id',
        document_name: 'Document name',
        note: 'Note',
        created_at: 'Created at',
        updated_at: 'Updated at',
        share_from: 'Share period from',
        share_to: 'Share period to',
        pre_shared_keyword: 'Pre shared keyword',
        max_access_count: 'Max access count',
        access_count: 'Access count',
        expiration: 'Expiration',
      },
      explanations: {
        documents: {
          document_list: '',
          share_document: '',
        },
      },
      notification: {
        success_delete_document: 'Successfully delete document',
        success_copy_url: 'Successfully copy url to clip board',
      },
      confirmation: {
        are_you_sure_to_delete_document: 'Are you sure to delete the document?',
        are_you_sure_to_sign_out: 'Are you sure to sign out?',
      },
    },
  },
  ja: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next',
    },
  },
  react: {
    wait: true,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'en',
  });

export default i18n;
