import { firebaseAuth } from '../firebaseAuth';
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  User as FirebaseUser,
} from 'firebase/auth';
import PropTypes from 'prop-types';
import Notification from '../components/Notification';
import { useState } from 'react';

export default function SignIn({
  currentUser,
  alertMessage,
  infoMessage,
}: {
  currentUser: FirebaseUser | null;
  alertMessage?: string | null;
  infoMessage?: string | null;
}) {
  const isUserExist = async (firebaseUid: string) => {
    return await fetch(
      `http://localhost:8080/users/?firebaseUid=${firebaseUid}`
    )
      .then((response) => response.json())
      .then((data) => {
        return data['user'].length !== 0;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };
  const createUser = async (firebaseUid: string) => {
    if (await isUserExist(firebaseUid)) {
    }
  };
  const signIn = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    provider.addScope('profile');
    signInWithPopup(firebaseAuth, provider)
      .then((r) => {
        const firebaseUid = r.user.uid;
        fetch(`http://localhost:8080/users/?firebaseUuid=${firebaseUid}`)
          .then((response) => response.json())
          .then((data) => {
            if (data['users'].length === 0) {
              const formData = new FormData();
              formData.append('firebaseUuid', firebaseUid);
              fetch(
                `http://localhost:8080/users/?firebaseUuid=${firebaseUid}`,
                { method: 'POST', body: formData }
              ).catch((e) => {
                console.log(e);
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [openNotification, setOpenNotification] = useState(false);

  return (
    <>
      {alertMessage ||
        (infoMessage && (
          <Notification
            infoMessage={infoMessage}
            openNotification={openNotification}
            setOpenNotification={setOpenNotification}
          />
        ))}

      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={signIn}
              >
                Sign in with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
