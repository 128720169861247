import { initializeApp } from "firebase/app";
import {getAuth, onAuthStateChanged} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCftOXFDIH2MHv613OkuH_85_LGG6GRO_4",
  authDomain: "development-4bc99.firebaseapp.com",
  projectId: "development-4bc99",
  storageBucket: "development-4bc99.appspot.com",
  messagingSenderId: "641158581404",
  appId: "1:641158581404:web:8254c46a7af06be038f159"
};

const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);
