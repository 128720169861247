import React from 'react';
import logo from './logo.svg';
import './App.css';
import Sidebar from './components/Sidebar';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import DocumentAccessControlList from './pages/DocumentAccessControlList';
import FileList from './pages/FileList';
import SignIn from './pages/SignIn';
import Receive from './pages/Receive';
import { onAuthStateChanged, User } from 'firebase/auth';
import { firebaseAuth } from './firebaseAuth';
import { useState } from 'react';

function App() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  onAuthStateChanged(firebaseAuth, (user) => {
    if (user) {
      setCurrentUser(user);
    }
  });

  if (currentUser) {
    return (
      <div className="flex flex-row">
        <Sidebar currentUser={currentUser} />
        <BrowserRouter>
          <Routes>
            <Route
              path="/signin"
              element={
                currentUser ? (
                  <Navigate replace to="/" />
                ) : (
                  <SignIn currentUser={currentUser} />
                )
              }
            />
            <Route
              path="/tracking"
              element={<DocumentAccessControlList currentUser={currentUser} />}
            />
            <Route
              path="/file-list"
              element={<FileList currentUser={currentUser} />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route
              path="/signin"
              element={<SignIn currentUser={currentUser} />}
            />
            <Route path="/receive" element={<Receive />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
