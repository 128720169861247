import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { firebaseAuth } from '../firebaseAuth';
import BasicDateTimePicker from './DatetimePicker';
import moment, { Moment } from 'moment-timezone';
import i18n from '../i18n';

export default function DocumentShareModal({
  openFileUploadModal,
  setOpenFileUploadModal,
  documentId,
  documentName,
}: {
  openFileUploadModal: boolean;
  setOpenFileUploadModal: Function;
  documentId: string;
  documentName: string;
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(openFileUploadModal);
  });
  const changeVisibility = (open: boolean) => {
    setOpenFileUploadModal(open);
    setOpen(open);
  };
  const [preSharedKeyword, setPreSharedKeyword] = useState('');
  const [maxAccessCount, setMaxAccessCount] = useState(0);
  const [note, setNote] = useState('');

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = moment().tz(timeZone);

  const [from, setFrom] = React.useState<Moment | null>(now);
  const [to, setTo] = React.useState<Moment | null>(now);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // do something
    const currentUser = firebaseAuth.currentUser;
    const formData = new FormData();
    formData.set('documentId', documentId);
    formData.set('preSharedKeyword', preSharedKeyword);
    formData.set('maxAccessCount', maxAccessCount.toString());
    if (from) {
      formData.set('from', from.utc().unix().toString());
    } else {
      formData.set('from', '');
    }
    if (to) {
      formData.set('to', to.utc().unix().toString());
    } else {
      formData.set('to', '');
    }

    currentUser!
      .getIdToken()
      .then((idToken) => {
        fetch(
          `http://localhost:8080/document-access-controls/?userId=${
            currentUser!.uid
          }`,
          {
            headers: { 'X-NE_USER_TOKEN': idToken },
            method: 'POST',
            body: formData,
            credentials: 'include',
          }
        ).then(async (response) => await response.json());
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {i18n.t('actions.share_document')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {i18n.t('explanations.documents.share_document')}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <form
                    className="space-y-8 divide-y divide-gray-200"
                    onSubmit={handleSubmit}
                    encType={'multipart/form-data'}
                    method={'POST'}
                  >
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                      <div className="space-y-6 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                          <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="documentId"
                                className="block w-full text-sm font-medium text-gray-700"
                              >
                                {i18n.t('common.document_id')}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="documentId"
                                  id="documentId"
                                  defaultValue={documentId}
                                  disabled
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="documentName"
                                className="block w-full text-sm font-medium text-gray-700"
                              >
                                {i18n.t('common.document_name')}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="documentName"
                                  id="documentName"
                                  defaultValue={documentName}
                                  disabled
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="username"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                {i18n.t('common.share_from')}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <BasicDateTimePicker
                                  value={from}
                                  setValue={setFrom}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="username"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                {i18n.t('common.share_to')}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <BasicDateTimePicker
                                  value={to}
                                  setValue={setTo}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="preSharedKeyword"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                {i18n.t('common.pre_shared_keyword')}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="preSharedKeyword"
                                    id="preSharedKeyword"
                                    maxLength={120}
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    value={preSharedKeyword}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setPreSharedKeyword(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="note"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                {i18n.t('common.note')}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="note"
                                    id="note"
                                    maxLength={120}
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    value={note}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setNote(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="accessLimitCount"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                {i18n.t('common.max_access_count')}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <div className="flex max-w-lg rounded-md shadow-sm">
                                  <input
                                    type="number"
                                    name="maxAccessCount"
                                    id="maxAccessCount"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    value={maxAccessCount}
                                    min="1"
                                    max="1000"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setMaxAccessCount(e.target.valueAsNumber);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            changeVisibility(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Create link
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
