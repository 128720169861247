import * as React from 'react';
import moment, { Moment } from 'moment-timezone';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';

export default function BasicDateTimePicker({
  value,
  setValue,
}: {
  value: Moment | null;
  setValue: Function;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
        label=""
        value={value}
        inputFormat={'YYYY-MM-DD hh:mm Z'}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        disablePast={true}
      />
    </LocalizationProvider>
  );
}
